import React, { useState } from "react";
import AddIcon from "assets/icons/add.svg";
import CancelIcon from "assets/icons/cancel.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateCertificates } from "state/SetCoachFormData";
import { addCoachFormData } from "state/CoachFormData";

function Certificate(props) {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.SetCoachFormData);
  const { certificates = [], setCertificates, handleSubmit } = props;
  const [dateToText, setdateToText] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [editCertificate, setEditCertificate] = useState(false);
  // function handleSubmit() {
  //   setEditCertificate(false);
  //   setEdit(false);
  // }

  // console.log(Data.certifications.length);

  const handleAddCertificate = () => {
    setCertificates([
      ...certificates,
      { name: "", issued_by: "", issued_on: "" },
    ]);

    setEdit(true);
  };

  const handleRemoveCertificate = (index) => {
    const updatedCertificates = certificates.filter((_, i) => i !== index);

    setCertificates(updatedCertificates);

    dispatch(addCoachFormData({ certifications: updatedCertificates }));
  };

  const handleChange = (e, index, key) => {
    const updatedCertificates = [...certificates];
    updatedCertificates[index] = {
      ...updatedCertificates[index],
      [key]: e.target.value,
    };
    setCertificates(updatedCertificates);
  };

  const handleDate = (e, index, key) => {
    const date = e.target.value;
    const updatedCertificates = [...certificates];
    updatedCertificates[index] = {
      ...updatedCertificates[index],
      issued_on: date ? date.split("-").reverse().join("-") : "",
    };
    setCertificates(updatedCertificates);
    dispatch(updateCertificates(updatedCertificates));
  };

  return (
    <>
      <div className="setting_box">
        <div className="profile_box_heading_div">
          <h2>Certifications</h2>
          {isEdit ? (
            <div className="flex">
              <p
                className="cancelButton"
                onClick={() => {
                  setEdit(false);
                  setEditCertificate(false);
                }}
              >
                Cancel
              </p>
              <p
                className="saveButton "
                onClick={() => {
                  handleSubmit();
                  setEdit(false);
                  dispatch(addCoachFormData({ certifications: certificates }));
                }}
              >
                Save
              </p>
            </div>
          ) : (
            <button
              className="edit_button m-0 ml-[0.3rem]"
              disabled={certificates == null}
              onClick={() => {
                setEdit(true);
                setEditCertificate(true);
              }}
            >
              Edit
            </button>
          )}
        </div>

        {certificates &&
          certificates.map((certificate, index) => (
            <div className="certificate_list_edit" key={index}>
              <div className="index_div">
                <div className="index">
                  <p>{index + 1}</p>
                </div>
                <p
                  className="revome_btn_phone"
                  onClick={() => handleRemoveCertificate(index)}
                >
                  <img src={CancelIcon} alt="" />
                </p>
              </div>

              <div className="certificate_input_box">
                {isEdit ? (
                  <input
                    type="text"
                    placeholder="Certificate of"
                    value={certificate.name}
                    onChange={(e) => handleChange(e, index, "name")}
                  />
                ) : (
                  <p className="lable_value">{certificate.name}</p>
                )}
                {isEdit ? (
                  <input
                    type="text"
                    placeholder="Certificate issued by"
                    value={certificate.issued_by}
                    onChange={(e) => handleChange(e, index, "issued_by")}
                  />
                ) : (
                  <p className="lable_value">{certificate.issued_by}</p>
                )}
                {isEdit ? (
                  <input
                    type={`${dateToText ? "text" : "date"}`}
                    onMouseEnter={() => setdateToText(false)}
                    onMouseLeave={() => setdateToText(true)}
                    value={certificate.issued_on}
                    onChange={(e) => handleDate(e, index, "issued_on")}
                  />
                ) : (
                  <p className="lable_value">{certificate.issued_on}</p>
                )}
              </div>

              <div>
                <p
                  className="revome_btn"
                  onClick={() => handleRemoveCertificate(index)}
                >
                  <img src={CancelIcon} alt="" />
                </p>
              </div>
            </div>
          ))}
        <div className="add_certificate">
          <p className="add_btn" onClick={handleAddCertificate}>
            Add More <img src={AddIcon} alt="" className="ml-3" />
          </p>
        </div>
      </div>
    </>
  );
}

export default Certificate;

import React, { useState, useEffect } from "react";
import Certificed from "assets/icons/certified.svg";
import FiveStar from "assets/icons/five_star.svg";
import Arow from "assets/icons/arow_more_details.svg";
import { Link } from "react-router-dom";
import Arows from "assets/icons/Group.svg";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";

const ViewCoachCard = ({ selectedActivity }) => {
  const pageNumber = 1;
  const pageSize = 20;
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [coachList, setCoachList] = useState([]);
  const selectedActivityString =
    selectedActivity && selectedActivity.length > 0
      ? selectedActivity.join(",")
      : null;
  const apiUrl = `${
    API_URL_CONSTANTS.COACHES_LIST
  }?pageNumber=${pageNumber}&pageSize=${pageSize}${
    selectedActivityString ? `&categories=${selectedActivityString}` : ""
  }`;
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchCoachList = async () => {
      const res = await getApis(apiUrl);
      if (res && !res.error) {
        if (res.data && res.data.content) {
          setCoachList(res.data.content);
        } else {
        }
      }
    };
    fetchCoachList();
  }, [apiUrl]);

  const handleToggleDetails = (refId) => {
    setShowDetails((prev) => !prev);
  };
  return (
    <>
      <div className="coach_card_desktop ">
        {coachList.map((data) => (
          <div
            key={data.refId}
            className="bg-[var(--white)] w-[95%] mx-auto my-4 shadow p-2 "
          >
            {/* Certificate div Start*/}
            <div className="flex ">
              {/* {data.certificate.map((items) => (
                <div className="flex flex-col pl-[1rem]">
                  <div className="flex items-center">
                    <img className="h-[1rem]" src={Certificed} alt="" />
                    <h6 className="text-[var(--blue)] font-normal">
                      {items.name}
                    </h6>
                  </div>
                  <div>
                    <p className="text-[0.6rem] mt-[-0.2rem]">({items.date})</p>
                  </div>
                </div>
              ))} */}
            </div>
            {/* Certificate div End*/}

            {/* card body start */}
            <div className="flex  pt-4 px-4">
              <div className="flex flex-col items-start py-2 w-[20%]">
                <img
                  className="rounded-[50%] w-[8rem] h-[8rem] object-cover"
                  src={`${API_URL_CONSTANTS.DOMAIN_NAME}${data.img}`}
                  alt=""
                />
                <div className="flex flex-col ">
                  <h6 className="text-[1.3rem] text-[var(--blue)]  font-normal pt-2">
                    {data.name}
                  </h6>

                  <span className="text-[.7rem]">
                    ( Coaching Since {data.coaching_since} )
                  </span>
                  <img className=" w-[5rem] " src={FiveStar} alt="" />
                  <span className="text-[.7rem]">
                    ( Athlete trained {data.athlete_trained} )
                  </span>
                </div>
              </div>
              <div className="flex flex-col p-2 w-[80%]">
                <div
                  className="min-h-32 text-[1.1rem] text-[var(--light-blue)] leading-7 tracking-wide break-words ql-editor list-disc list-inside"
                  dangerouslySetInnerHTML={{ __html: data.about }}
                />
                <div className="flex justify-between">
                  <div className="flex pt-[0.6rem] w-[75%]">
                    {/* Coaches section - 70% */}
                    <div className="w-[70%] flex">
                      <h6 className="text-[var(--blue)] font-normal mr-2">
                        Coaches
                      </h6>
                      <div
                        className={`2xl:flex ${
                          showDetails ? "2xl:grid" : "2xl:flex"
                        }`}
                      >
                        <ul
                          className={`viewCoah_li grid lg:grid-cols-2 ${
                            showDetails ? "2xl:grid-cols-2" : "2xl:grid-cols-4"
                          }`}
                        >
                          {/* {data.coaches?.map((coach, index) => (
          <li key={index}>
            <span>
              {showDetails
                ? `${capitalizeFirstLetter(coach.category)}: ${coach.value}`
                : capitalizeFirstLetter(coach.category)}
            </span>
          </li>
        ))} */}
                        </ul>
                      </div>
                    </div>

                    {/* More Details section - 20% */}
                    <div
                      className="w-[20%] flex items-center justify-end ml-8 pl-4 cursor-pointer text-[var(--green)] text-[13px] underline -mt-2"
                      onClick={handleToggleDetails}
                    >
                      {showDetails ? (
                        <>
                          <img className="rotate-180 pl-2" src={Arow} alt="" />
                          <span>Less Details</span>
                        </>
                      ) : (
                        <>
                          <span>More Details</span>
                          <img className="pl-2 w-5 h-5" src={Arow} alt="" />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="pt-2  w-[25%]">
                    <p
                      className={`text-[var(--blue)] flex text-[1rem] md:grid sm:grid lg:grid xl:grid justify-end ${
                        showDetails ? "2xl:!grid " : "2xl:flex"
                      }`}
                    >
                      To View Profile
                      <div
                        className={`flex  ${showDetails ? "ml-0 " : "ml-2"}`}
                      >
                        <Link
                          to="/coachdetails"
                          state={{ refId: data.refId }}
                          className=" text-[0.9rem]  text-[#007c3c] sm:ml-0 md:ml-0 xl:ml-0 mt-[2px]"
                        >
                          Click Here
                        </Link>
                        <img
                          src={Arows}
                          className="w-3 h-3  ml-1 mt-[6px]"
                        ></img>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ViewCoachCard;

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import CoachImg from "assets/img/coachImg.png";
import logoImg from "assets/img/tofAppIcon1.png";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";
import { HelmetProvider } from "react-helmet-async";
import SEO from "hooks/Seo";

function Coach(props) {
  const localStorageData = getlocalStorageData();
  const AppData = useContext(AppProps);

  let [isPlus, setPlus] = useState([false, false, false, false]);

  let changeCard = (index) => {
    const newPlusState = [...isPlus];
    newPlusState[index] = !newPlusState[index];
    setPlus(newPlusState);
  };

  const arr = [
    {
      id: "0",
      plus: "+",
      sub: "-",
      heding: "Unlock athlete potential with custom training plans.",
      para: "Empower your athletes to achieve their full potential with personalized training programs designed to enhance their performance. With continuous insights and motivating guidance, you can help them reach their goals and go beyond their limits. Join a vibrant community of athletes and coaches, and train anytime, anywhere to achieve success and excellence.",
    },
    {
      id: "1",
      plus: "+",
      sub: "-",
      heding: "Flexible coaching pricing for athletes",
      para: "Coaches are given full autonomy to create and manage their coaching plans. They can set their own prices based on their expertise and the services they offer. Athletes have the freedom to choose from a variety of plans that fit their goals and budget. This flexible approach ensures that athletes receive customized coaching services that are tailored to their needs and preferences, enriching their training experience.",
    },
    {
      id: "2",
      plus: "+",
      sub: "-",
      heding: "Adjust athlete training with the APP. ",
      para: "Coaches can use the app to monitor and adjust training plans and races, to improve overall athlete progress. By using key metrics such as speed, distance, and heart rate, coaches can set goals and adjust training for optimal performance. Whether they are on a desktop or mobile device, coaches can seamlessly oversee athlete profiles and track performance metrics",
    },
    // {
    //   id: "3",
    //   plus: "+",
    //   sub: "-",
    //   heding: "Manage everything in one place",
    //   para: "Enjoy the freedom and convenience of managing your coaching business from anywhere, at any time. With cross-platform support, you can access Tackofit's powerful coaching tools and resources from your desktop, laptop, tablet, or smartphone, ensuring that you're always connected to your athletes and able to provide the support they need. ",
    // },
  ];

  return (
    <HelmetProvider>
      <div
        className="coach_div h-auto bg-[var(--light-grey)] py-[2rem] px-[4rem] "
        id="coach"
      >
        {/* <SEO
          title="Become a Coach | Tackofit"
          description="Join Tackofit as a coach and help athletes achieve their full potential with personalized training plans and flexible coaching options."
          canonicalUrl="https://www.trackofit.com/become-a-coach"
        /> */}
        <div className="coach_heading text-center ">
          <h1 className="text-[var(--blue)] font-medium text-[2.5rem] pb-[2rem]">
            Become a coach
          </h1>
        </div>
        <div className="coach_info_box  flex p-[1rem] mb-[2rem]">
          <div className="coach_img_phone">
            <img src={CoachImg} alt="" />
          </div>
          <div className="coach_info bg-[] text-center w-[50%]">
            {arr.map((item) => (
              <div
                key={item.id}
                className=" w-[90%] text-left mx-auto mb-4 p-4 bg-[var(--white)]"
              >
                <div className="heading_box flex justify-between items-center">
                  <h4 className="text-[var(--ligh-blue)] text-[1.2rem] font-normal ">
                    {item.heding}
                  </h4>
                  <span
                    className="text-[2rem] cursor-pointer"
                    onClick={() => changeCard(item.id)}
                  >
                    {isPlus[item.id] ? item.sub : item.plus}
                  </span>
                </div>
                {isPlus[item.id] && (
                  <p className="my-2 text-[var(--light-blue)] font-light text-4">
                    {item.para}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div className="coach_img w-[50%] text-center">
            <img
              className="object-cover w-[90%] h-[20rem]"
              src={CoachImg}
              alt=""
            />
          </div>
        </div>
        <div className="join_box flex justify-center items-center w">
          <div className="join_text w-[50%] px-[2.2rem]">
            <div className=" join_text_heading flex items-center justify-start text-[var(--blue)]  font-light ">
              <h1 className="inline-block text-[var(--blue)]">Ready to Join</h1>
              <img
                src={logoImg}
                alt=""
                className=" w-24 h-9 ml-3  mix-blend-darken"
              />
            </div>

            <p className="para text-left font-light text-[var(--light-blue)]">
              Are you passionate about triathlon and eager to share your
              expertise with aspiring athletes? Join Tackofit as a coach and
              take your coaching journey to new heights. Here's what you can do
              as a coach on our platform
            </p>
          </div>
          <div className="join_button flex flex-col my-[5rem] mx-auto">
            {AppData.isSignedIn ? (
              <>
                {localStorageData.role === "Coach" ? (
                  <Link to="/viewaccount">View Account</Link>
                ) : (
                  <>
                    {localStorageData.role === "Athlete" && (
                      <>
                        {AppData.coachSignUpStatus === "3" ||
                        AppData.coachSignUpStatus === "5" ||
                        AppData.coachSignUpStatus === "null" ? (
                          <>
                            <Link to="/coachform">Get Started</Link>
                          </>
                        ) : (
                          <>
                            {AppData.coachSignUpStatus ===
                              "Pending Verification" && (
                              <>
                                <a>{AppData.coachSignUpStatus}</a>
                                {/* <Link to="/viewcoachform">
                                  Pending Verification
                                </Link> */}
                                {/* <p className="text-center">(View Form)</p> */}
                              </>
                            )}
                            {/* {AppData.coachSignUpStatus === "Need Action" && (
                              <Link to="/editcoachform">Need Action</Link>
                            )} */}
                            {AppData.coachSignUpStatus === "Verified" && (
                              <>
                                <a
                                  onClick={() => {
                                    AppData.SwitchAccountBtn(
                                      localStorageData.ref_id
                                    );
                                  }}
                                >
                                  Verified
                                </a>
                                <div>
                                  <p>Click to Switch to Coach Account</p>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <a
                  onClick={() => {
                    AppData.setOpen(true);
                    AppData.setSignInOpen(true);
                  }}
                >
                  Get Started
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default Coach;

import React, { useState, useEffect, useContext } from "react";
import { SwitchAthleteToCoach } from "api/HomeController";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { Header } from "api/Signin";
import styled from "styled-components";
import axios from "axios";
import background1 from "assets/img/cover_photo_login_page.jpg";
import FooterSection from "components/footer/FooterSection";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import PopUpLogin from "components/signin/PopUpLogin";
import ForgorPasswordPopUp from "components/forgetPassword/ForgotPasswordPopUp";
import AboutUs from "pages/homePage/AboutUs/AboutUs";
import Coach from "pages/homePage/coach/Coach";
import SignUpPopUp from "components/signup/SignUpPopUp";
import SuccessPopup from "components/popups/SuccessPopup";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";
import { HelmetProvider } from "react-helmet-async";
import SEO from "hooks/Seo";

export function Home() {
  const AppData = useContext(AppProps);

  //check if clicked  SIGN UP button (sign up popup will come)
  const [isSignUp, setSignUp] = useState(false);

  // FORGET PASSWORD
  const [isForgotPassword, setForgotPassword] = useState(false);

  // Verified button clicked in coach section
  const [verifiedClicked, setVerifiedClicked] = useState();

  const localStorageData = getlocalStorageData();

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //API call for coach signup status
  //2,6, => view form / null,3,5 => get Stated page / 4=> form editable / 1=> pending verfication
  useEffect(() => {
    async function getoachStatus() {
      const header = Header();
      return axios
        .get(API_URL_CONSTANTS.GET_COACH_SIGNUP_STATUS, {
          headers: header,
        })
        .then((res) => {
          if (res.data.is_submitted === false) {
            AppData.setCoachSignupStatus("null");
          } else {
            if (res.data.request_status === 1) {
              AppData.setCoachSignupStatus(res.data.message); // message =  pending verification
            } else if (
              res.data.request_status === 2 &&
              localStorageData.role === "Athlete"
            ) {
              AppData.setCoachSignupStatus(res.data.message); // message = Verified
            } else if (
              res.data.request_status === 2 &&
              localStorageData.role === "Coach"
            ) {
              AppData.setCoachSignupStatus("View Profile");
            } else if (res.data.request_status === 4) {
              AppData.setCoachSignupStatus(res.data.message); // Awaiting Details
            } else if (res.data.cs_status === 6) {
              AppData.setCoachSignupStatus("View Profile");
            } else if (res.data.request_status === 3) {
              AppData.setCoachSignupStatus("3"); // Rejected
            } else if (res.data.request_status === 5) {
              AppData.setCoachSignupStatus("5"); // Cancelled
            }
          }
        })
        .catch((error) => {
          return error;
        });
    }

    if (AppData.isSignedIn) {
      getoachStatus();
    }
  }, [AppData.isSignedIn]);

  return (
    <HelmetProvider>
      <SEO
        title="Home | Tackofit"
        descriptionName="Home"
        description="For the Athletes By the Coaches"
        canonicalUrl="https://www.trackofit.com"
      />
      <MiddlePageView>
        <MainContainer>
          <BackgroundImage id="bg">
            {isForgotPassword && (
              <div className="forgotpassword_popup_box_phone">
                <ForgorPasswordPopUp setForgotPassword={setForgotPassword} />
              </div>
            )}
            <SuccessPopup
              setIsSignupDone={AppData.setCoachFormSubmit}
              isSignupDone={AppData.coachFormSubmit}
              message={{
                heading: "Coach Request Is Submitted successfully!",
                message: "your request will get verified in 5-6 working days.",
              }}
            />
            <Nav setSignUp={setSignUp} isSignUp={isSignUp} />
            <PhoneNavBar />
            <SignUpPopUp setSignUp={setSignUp} isSignUp={isSignUp} />
            <PopUpLogin isSignUp={isSignUp} setSignUp={setSignUp} />

            <PopUpLoginP
              isForgotPassword={isForgotPassword}
              setForgotPassword={setForgotPassword}
              isSignUp={isSignUp}
              setSignUp={setSignUp}
            />

            <div className=" relative top-[60%] block mx-auto text-center ">
              <div id="heading" className="header_text uppercase">
                <p className="header_text text-[var(--dark-orange)] text-[5rem]">
                  TRACKOFIT
                </p>
                <span
                  id="para"
                  className="block text-[var(--white)] text-[1rem] tracking-widest"
                >
                  For the Athletes by the coaches
                </span>
              </div>
            </div>
          </BackgroundImage>

          <Coach />
          {/* <Athele /> */}
          <AboutUs />
          <MiddleView>
            <FooterSection />
          </MiddleView>
        </MainContainer>
      </MiddlePageView>
    </HelmetProvider>
  );
}

const MainContainer = styled.div`
  height: 60px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: grid;
  align-items: center;
`;

const BackgroundImage = styled.div`
  background-image: linear-gradient(#00000063, #00000087), url(${background1});
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
`;

const MiddleView = styled.div``;

const MiddlePageView = styled.div`
  height: 100vh;
`;

import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import kvideo from "assets/img/KVideo.mp4";
import Guarantee from "assets/img/Guarantee.png";
import stars from "assets/icons/stars.svg";
import Arrow_Right from "assets/icons/arrow right.svg";
import locations from "assets/icons/Location.svg";
import Globe from "assets/icons/Globe.svg";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ViewCoachingPlan from "pages/coachingPlans/coachingPlansComponents/viewCoachingPlan";
import { QueryToCoach } from "api/UserAccountController";
import FooterSection from "components/footer/FooterSection";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
function CoachDetails() {
  const location = useLocation();
  const { refId } = location.state || {};
  const [clickHere, setClickHere] = useState(false);
  const mobile = localStorage.getItem("mobile") || "";
  const userName = sessionStorage.getItem("name");
  const Email =
    sessionStorage.getItem("email") || localStorage.getItem("email") || "";
  const [sticky, setSticky] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [queryError, setQueryError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      if (/^\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  async function handleSubmit(e) {
    setClickHere(true);
    e.preventDefault();

    const { phone, email, topic, message } = formData;
    if (!phone) {
      setQueryError("Please enter a valid phone number.");
      return;
    }
    if (!email) {
      setQueryError("Please enter your email address.");
      return;
    }
    if (!topic) {
      setQueryError("Please enter a topic.");
      return;
    }
    if (!message) {
      setQueryError("Please enter your message.");
      return;
    }

    setQueryError(""); // Clear any previous error messages

    // Submit the query (replace with your actual submission function)
    QueryToCoach(phone, email, topic, message, refId);

    // Clear the form data
    setFormData({
      name: "",
      email: "",
      phone: "",
      topic: "",
      message: "",
    });

    // Optionally reset clickHere and queryError states as well
    setClickHere(false);
    setQueryError("");
  }

  useEffect(() => {
    setFormData({ email: Email });
  }, []);

  const [coachDetails, setCoachDetails] = useState(null);
  const apiUrl = `${API_URL_CONSTANTS.COACHES_DETAILS}/${refId}`;

  useEffect(() => {
    const fetchCoachDetails = async () => {
      try {
        const res = await getApis(apiUrl);
        if (res && !res.error) {
          setCoachDetails(res.data);
        } else {
        }
      } catch (error) {
        console.error("Error fetching coach details:", error);
      }
    };
    fetchCoachDetails();
  }, [apiUrl]);

  useEffect(() => {}, [coachDetails]);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
      setIsScrolling(true);
    } else {
      setSticky(false);
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error(`Element with id ${sectionId} not found.`);
    }
  };

  return (
    <>
      <Nav className="navbar" />
      <PhoneNavBar className="navbar" />
      {coachDetails && (
        <div className="relative top-16 bg-[#d9d9d9]">
          {isScrolling && (
            <header
              className={`${sticky ? "header sticky" : "header"} grid top-16`}
            >
              <div className="phone_header">
                <div className="flex-col">
                  <div className=" bg-[#d9d9d9]">
                    <img
                      src={coachDetails.img}
                      className="h-[25vh] w-[25vh] sm:left-8 left-20 relative profile_photo border-white border-2"
                    />
                  </div>
                  <div className="bg-[var(--blue)] md:!-mt-9 md:-mb-16 sm:!-mt-12 sm:!-mb-18 p-4">
                    <div className="grid">
                      <div className="flex ml-3 mr-3 justify-between -mb-3">
                        <div className="flex flex-col justify-center items-start mt-3">
                          <p className="greeting textWhite text-[1.2rem] sm:text-[0.8rem]">
                            Hello,
                          </p>
                          <h2 className="coach-name textWhite text-[1.2rem] sm:text-[1.1rem] -mt-3">
                            I'm {coachDetails.name}
                          </h2>
                          <p className="coaching-since textWhite text-[0.8rem] -mt-3">
                            Coaching since {coachDetails.coaching_since}
                          </p>
                          <div className="flex items-center -mt-6">
                            <img
                              src={stars}
                              className="w-16 h-16"
                              alt="Stars"
                            />
                            <p className="textWhite text-[0.8rem] ml-2">
                              (28 Athletes)
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col justify-center items-start">
                          <div className="flex items-center textWhite -mt-3">
                            <img
                              src={Guarantee}
                              className="w-4 h-4 mr-2"
                              alt="Guarantee"
                            />
                            {coachDetails.certifications.length > 0 && (
                              <div className="grid text-white">
                                <p className="!">
                                  {coachDetails.certifications[0].name}
                                </p>
                              </div>
                            )}
                          </div>
                          <ul className="list-none grid mt-1 ml-auto">
                            <li className="text-white">Run</li>
                            <li className="text-white">Bike</li>
                            <li className="text-white">Swim</li>
                          </ul>
                        </div>
                      </div>
                      <div className="flex justify-center ">
                        <p
                          className="textWhite text-[0.8rem] mt-2 mr-3 font-thin"
                          onClick={() => scrollToSection("aboutMe")}
                        >
                          About Me
                        </p>
                        <p
                          className="textWhite text-[0.8rem] mr-3 font-thin"
                          onClick={() => scrollToSection("certificates")}
                        >
                          Certifications
                        </p>
                        <p
                          className="textWhite text-[0.8rem] font-thin mr-3"
                          onClick={() => scrollToSection("coachingPlans")}
                        >
                          Coaching Plans
                        </p>
                        <p
                          className="textWhite text-[0.8rem] font-thin"
                          onClick={() => scrollToSection("contactUs")}
                        >
                          Connect with me
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col desktop_header ">
                <div className="flex">
                  <div className="flex flex-col w-1/5">
                    <div className="h-1/3 bg-[#d9d9d9] pt-2">
                      <img
                        src={coachDetails.img}
                        className="h-[25vh] w-[25vh] sm:left-8 left-20 relative profile_photo border-white border-2"
                      />
                    </div>
                    <div className="h-2/3 bg-[var(--blue)]"></div>
                  </div>
                  <div className="flex flex-col w-4/5">
                    <div className="h-1/3 bg-[#d9d9d9]"></div>
                    <div className="h-2/3 bg-[var(--blue)] pl-10 pr-10 pt-3">
                      <div className="flex justify-between  ">
                        <p className=" textWhite text-[0.9rem] font-thin">
                          Hello,
                        </p>
                        <div className="flex">
                          <img src={Guarantee} className="w-4 h-4 mr-2" />
                          {coachDetails.certifications.length > 0 && (
                            <div className="grid">
                              <p className="text-white font-thin">
                                {coachDetails.certifications[0].name}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between -mt-1">
                        <div className="flex-col ">
                          <p className=" textWhite text-[1.1rem] font-thin">
                            {coachDetails.name}
                          </p>
                        </div>
                        <div className="flex">
                          <div className="flex mt-[-2px]">
                            <p className="textWhite text-[1.2rem] mr-2 ">
                              Coaches
                            </p>
                            <img
                              src={Arrow_Right}
                              className="w-5 h-5 mt-[0.2rem] mr-1"
                            />
                          </div>
                          {coachDetails.coaches.map((coach, index) => (
                            <div
                              key={coach.category}
                              className="flex flex-col items-start"
                            >
                              <div className="flex">
                                {index === 0 ? (
                                  <p className="text-[var(--white)] font-thin text-[1.1rem] sm:text-[1.1rem] ml-5">
                                    {coach.category.charAt(0).toUpperCase() +
                                      coach.category.slice(1)}
                                  </p>
                                ) : (
                                  <li className="list-disc text-[var(--white)] font-thin text-[1.1rem] sm:text-[1.1rem] ml-5">
                                    {coach.category.charAt(0).toUpperCase() +
                                      coach.category.slice(1)}
                                  </li>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex">
                          <p className=" textWhite text-[0.9rem]  font-thin">
                            Coaching since {coachDetails.coaching_since}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex">
                          <img src={stars} />
                          <p className="textWhite ml-2 font-thin text-[0.8rem]">
                            ( 28 Athletes )
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center bg-[var(--blue)] ">
                  <p
                    className="textWhite text-[1.1rem] mt-2 mr-8 font-thin"
                    onClick={() => scrollToSection("aboutMe")}
                  >
                    About Me
                  </p>
                  <p
                    className="textWhite text-[1.1rem] mr-8 font-thin"
                    onClick={() => scrollToSection("certificates")}
                  >
                    Certifications
                  </p>
                  <p
                    className="textWhite text-[1.1rem] font-thin mr-8"
                    onClick={() => scrollToSection("coachingPlans")}
                  >
                    Coaching Plans
                  </p>
                  <p
                    className="textWhite text-[1.1rem] font-thin"
                    onClick={() => scrollToSection("contactUs")}
                  >
                    Connect with me
                  </p>
                </div>
              </div>
            </header>
          )}
          {!isScrolling && (
            <>
              <div className="grid">
                <div className="z-10 relative">
                  {coachDetails.video ? (
                    <video
                      id="video"
                      className="w-[49%] sm:!ml-3 sm:w-[94%] md:w-[94%] ml-4 mt-4"
                      controls
                      src={`${API_URL_CONSTANTS.DOMAIN_NAME}${coachDetails.video}`}
                    ></video>
                  ) : (
                    <img
                      src={`${API_URL_CONSTANTS.DOMAIN_NAME}${coachDetails.profile_photo}`}
                      alt="Coach visual representation"
                      className="rounded-[50%] w-[20%] sm:!ml-3 sm:w-[94%] md:w-[94%] ml-[10%] mt-4 justify-center"
                    />
                  )}
                </div>
              </div>
              <div className="phone_view">
                <div className="bg-[var(--blue)] !-mt-32 -mb-12 md:!-mt-9 md:-mb-16 sm:!-mt-12 sm:!-mb-18 grid">
                  <div className=" justify-between items-center mt-10 ml-4">
                    <div className="flex">
                      <div className="grid">
                        <p className="greeting textWhite text-[1.2rem]">
                          Hello,
                        </p>
                        <h2 className="coach-name textWhite text-[1.2rem] -mt-4">
                          I'm {coachDetails.name}
                        </h2>
                      </div>
                      <div className="flex items-center textWhite mr-4 ml-auto">
                        <img src={Guarantee} className="w-4 h-4 mr-2" />
                        {coachDetails.certifications.length > 0 && (
                          <div className="grid">
                            <p>{coachDetails.certifications[0].name}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="coaching-since textWhite -mt-4 text-[0.8rem]">
                      Coaching since {coachDetails.coaching_since}
                    </p>
                    <div className="flex">
                      <img src={stars} />
                      <p className="textWhite text-[0.8rem] ml-2">
                        ( 28 Athletes )
                      </p>
                    </div>
                    <div>
                      <div className="flex mt-2">
                        <div className="flex -mt-1">
                          <p className="textWhite text-[1.2rem] mr-6 ">
                            Coaches
                          </p>
                          <img
                            src={Arrow_Right}
                            className="w-5 h-5 mt-[0.2rem] mr-1"
                          />
                        </div>
                        {coachDetails.coaches.map((coach, index) => (
                          <div
                            key={coach.category}
                            className="flex flex-col items-start"
                          >
                            <div className="flex">
                              {index === 0 ? (
                                <p className="text-[var(--white)] font-thin text-[1.1rem] sm:text-[1.1rem] ml-5">
                                  {coach.category.charAt(0).toUpperCase() +
                                    coach.category.slice(1)}
                                </p>
                              ) : (
                                <li className="list-disc text-[var(--white)] font-thin text-[1.1rem] sm:text-[1.1rem] ml-5">
                                  {coach.category.charAt(0).toUpperCase() +
                                    coach.category.slice(1)}
                                </li>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desktop_view">
                <div className="bg-[var(--blue)] !-mt-20 -mb-12 md:-mt-16 md:-mb-16 sm:!-mt-8 sm:!-mb-8 flex">
                  <div
                    className={
                      coachDetails.video ? "w-1/2 ml-32" : "w-1/3 ml-32"
                    }
                  ></div>
                  <div
                    className={
                      coachDetails.video ? "w-1/2 ml-32" : "w-2/3 ml-12"
                    }
                  >
                    <div className="flex justify-between items-center">
                      <p className="greeting textWhite font-thin">Hello,</p>
                      <div className="flex items-center textWhite mr-4">
                        <img src={Guarantee} className="w-6 h-6 mr-2" />
                        {coachDetails.certifications.length > 0 && (
                          <div className="grid">
                            <p>{coachDetails.certifications[0].name}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <h2 className="coach-name textWhite">
                      I'm {coachDetails.name}
                    </h2>
                    <p className="coaching-since textWhite">
                      Coaching since {coachDetails.coaching_since}
                    </p>
                    <div className="flex">
                      <img src={stars} />
                      <p className="textWhite ml-2">
                        ( {coachDetails.athlete_trained} Athletes )
                      </p>
                    </div>
                    <div className="flex mt-2">
                      <div className="flex">
                        <p className="textWhite text-[1.2rem] mr-6 ">Coaches</p>
                        <img
                          src={Arrow_Right}
                          className="w-6 h-6 mt-[0.2rem]"
                        />
                      </div>
                      {coachDetails.coaches.map((coach, index) => (
                        <div
                          key={coach.category}
                          className="flex flex-col items-start"
                        >
                          <div className="flex">
                            {index === 0 ? (
                              <p className="text-[var(--white)] font-thin text-[1.1rem] sm:text-[1.1rem] ml-5">
                                {coach.category.charAt(0).toUpperCase() +
                                  coach.category.slice(1)}
                              </p>
                            ) : (
                              <li className="list-disc text-[var(--white)] font-thin text-[1.1rem] sm:text-[1.1rem] ml-5">
                                {coach.category.charAt(0).toUpperCase() +
                                  coach.category.slice(1)}
                              </li>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="mx-6">
            <div className="flex md:grid mt-20 w-[90%] mx-auto sm:flex-col md:w-auto ">
              {/* About Me Section */}
              <div
                className="w-1/2 pr-20 sm:w-full sm:pr-0 md:pr-0 md:w-full lg:pr-6 scroll-me-section sm:scroll-phone-about md:scroll-phone-about"
                id="aboutMe"
              >
                <div className="flex justify-between">
                  <p className="text-[1.5rem] text-[var(--blue)]">About Me</p>
                  <div className="flex desktop_location">
                    <img
                      src={locations}
                      className="w-4 h-4 mt-1"
                      alt="Location"
                    />
                    <p className="text-[1.1rem] text-[var(--blue)]">
                      {coachDetails.location}
                    </p>
                  </div>
                  <div className="phone_location">
                    <div className="flex">
                      <img
                        src={locations}
                        className="w-4 h-4 1"
                        alt="Location"
                      />
                      <p className="text-[0.8rem] text-[var(--blue)]">
                        {coachDetails.location}
                      </p>
                    </div>
                    <div className="flex">
                      <img src={Globe} className="w-4 h-4 " alt="Location" />
                      <p className="text-[0.8rem] text-[var(--blue)]">
                        {coachDetails.languages}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="text-[1rem] break-all"
                  dangerouslySetInnerHTML={{ __html: coachDetails.about }}
                />
              </div>
              {/* Certifications Section */}
              <div
                className="w-1/2 grid pl-20 sm:w-full sm:!pl-0 sm:mt-6 md:pl-0 md:w-full lg:pl-6 sm:scroll-phone-certificate md:scroll-phone-certificate scroll-me-section"
                id="certificates"
              >
                <p className="text-[1.5rem] text-[var(--blue)] md:mt-4">
                  Certifications
                </p>
                {coachDetails.certifications &&
                coachDetails.certifications.length > 0 ? (
                  coachDetails.certifications.map((certificate) => (
                    <div className="grid" key={certificate.name}>
                      <p className="flex justify-between mt-3">
                        <li className="text-[1rem] text-[var(--blue)]">
                          {certificate.name}
                        </li>
                        <p>({certificate.issued_on})</p>
                      </p>
                      <p className="mt-[-2px]">{certificate.issued_by}</p>
                    </div>
                  ))
                ) : (
                  <p className="text-[1rem] text-[var(--blue)] mt-3">None</p>
                )}
              </div>
            </div>
            <div className="flex mt-6 w-[90%] mx-auto sm:flex-col md:w-auto md:grid">
              <div className="w-1/2 sm:w-full md:w-full">
                <p className="text-[1.5rem] text-[var(--blue)]">
                  Preferred Athlete Levels
                </p>
                <p className="text-[1rem] text-[var(--blue)]">
                  {coachDetails.athlete_level}
                </p>
              </div>
              <div className="desktop_languages">
                <div className="w-1/2 pl-20 sm:w-full sm:pl-0 sm:mt-6 md:pl-0 md:mt-4 md:w-auto lg:pl-6">
                  <p className="text-[1.5rem] text-[var(--blue)]">Languages</p>
                  <p className="text-[1rem] text-[var(--blue)]">
                    {coachDetails.languages}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex mt-6 w-[92%] mx-auto bg-white p-4 rounded-[2px]">
              <div className="w-full flex sm:grid md:grid sm:self-center">
                <div className="w-1/4 sm:w-full md:w-full">
                  <p className="text-[1.5rem] text-[var(--blue)] mx-auto">
                    Coaching For
                  </p>
                </div>

                {coachDetails.coaches.length === 2 && (
                  // Show 2 coaches in one row with a vertical line in the middle
                  <div className="grid grid-cols-2 w-3/4 gap-4 items-start sm:w-full md:w-full">
                    {coachDetails.coaches.map((coach, index) => (
                      <div
                        key={coach.category}
                        className={`flex-col w-4/5 sm:flex md:flex mt-2 ${
                          index === 0 ? "border-r-2 border-[#000000]" : ""
                        } px-4`}
                      >
                        <p className="text-[var(--blue)] font-thin text-[1.1rem] sm:text-[1.1rem]">
                          {coach.category.charAt(0).toUpperCase() +
                            coach.category.slice(1)}
                        </p>
                        <p className="text-[var(--blue)] font-thin text-[1rem] sm:text-[0.9rem] sm:mb-2 sm:w-full -mt-1">
                          {coach.value}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {coachDetails.coaches.length === 4 && (
                  // Show 4 coaches with 2 per row and a vertical line in the middle
                  <div className="grid grid-cols-2 w-3/4 items-start justify-between sm:grid sm:w-full md:w-full">
                    {coachDetails.coaches.map((coach, index) => (
                      <div
                        key={coach.category}
                        className={`flex-col w-4/5 sm:flex md:flex mt-2 ${
                          index % 2 === 0 ? "border-r-2 border-gray-300" : ""
                        } px-4`}
                      >
                        <p className="text-[var(--blue)] font-thin text-[1.1rem] sm:text-[1.1rem]">
                          {coach.category.charAt(0).toUpperCase() +
                            coach.category.slice(1)}
                        </p>
                        <p className="text-[var(--blue)] font-thin text-[1rem] sm:text-[0.9rem] sm:mb-2 sm:w-full -mt-1">
                          {coach.value}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {coachDetails.coaches.length !== 2 &&
                  coachDetails.coaches.length !== 4 && (
                    // Show 3 or more coaches with flexible grid layout
                    <div className="grid grid-cols-3 gap-4 w-3/4 sm:w-full md:w-full">
                      {coachDetails.coaches.map((coach) => (
                        <div
                          key={coach.category}
                          className="flex flex-col items-start"
                        >
                          <div className="flex-col border-r-2 border-gray-300 px-4 w-4/5 md:w-full sm:w-full">
                            <p className="text-[var(--blue)] font-thin text-[1.1rem] sm:text-[1.1rem]">
                              {coach.category.charAt(0).toUpperCase() +
                                coach.category.slice(1)}
                            </p>
                            <p className="text-[var(--blue)] font-thin text-[1rem] sm:text-[0.9rem] sm:mb-2 sm:w-full">
                              {coach.value}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
            <div
              className="mt-10 w-[90%] mx-auto md:w-auto sm:scroll-phone-about md:scroll-phone-about scroll-me-section"
              id="coachingPlans"
            >
              <div className="text-[1.5rem] text-[var(--blue)] justify-center text-center">
                Coaching Plans
                <ViewCoachingPlan refId={refId} />
              </div>
            </div>
          </div>
          <div className="contact_image">
            <div
              className="  flex flex-col items-center justify-center p-6 contact-section sm:scroll-phone-contact md:scroll-phone-contact"
              id="contactUs"
            >
              <h2 className="text-[1.5rem] text-[var(--blue)] font-thin  pb-2">
                Have a question? Contact me.
              </h2>
              <form
                onSubmit={handleSubmit}
                className="w-full max-w-md space-y-4"
              >
                <input
                  type="text"
                  name="name"
                  placeholder="First and last name"
                  value={formData.name || userName}
                  onChange={handleChange}
                  readOnly={!!userName}
                  className="input_box"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email || Email}
                  onChange={handleChange}
                  className="input_box"
                />
                <div className="relative">
                  <input
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone || mobile}
                    onChange={handleChange}
                    readOnly={!!mobile}
                    type="text"
                    inputMode="numeric"
                    maxLength={10}
                    className={`input_box ${
                      clickHere && formData.phone === "" ? "input-error" : ""
                    }`}
                  />
                </div>

                <div className="relative">
                  <input
                    type="text"
                    name="topic"
                    placeholder="Topic"
                    value={formData.topic}
                    onChange={handleChange}
                    className="input_box"
                  />
                </div>
                <div className="relative">
                  <textarea
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    className="input_box h-32"
                  />
                </div>
                {queryError && (
                  <div className="query_message">{queryError}</div>
                )}
                <button
                  type="submit"
                  className="w-full p-3 bg-[var(--blue)] text-white rounded hover:bg-[var(--blue)]"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <FooterSection></FooterSection>
        </div>
      )}
    </>
  );
}

export default CoachDetails;

import React, { useContext, useEffect, useState } from "react";
import RepeatIcon from "assets/icons/repeat_icon.svg";
import { CalendarData } from "utility/constants/useContext";
import RunIcon from "assets/icons/RunIcon.svg";
import SwimICon from "assets/icons/SwimIcon.svg";
import BikeIcon from "assets/icons/BikeIcon.svg";

function TrainingSlider() {
  const Props = useContext(CalendarData);
  const stepsData = Props.stepsData;

  // convert time 00:1:00 to 1hr
  function formatTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    // Handle different cases
    if (hours > 0 && minutes > 0 && seconds === 0) {
      return `${hours}:${minutes} hr`;
    } else if (hours === 0 && minutes > 0 && seconds === 0) {
      return `${minutes} mins`;
    } else if (hours === 0 && minutes === 0 && seconds > 0) {
      return `${seconds} secs`;
    } else if (hours > 0 && minutes === 0 && seconds === 0) {
      return `${hours} hr`;
    } else if (hours > 0 && minutes > 0 && seconds > 0) {
      return `${hours}:${minutes}:${seconds} hr`;
    } else {
      return `${minutes}:${seconds} min`;
    }
  }
  const targetMapping = {
    PC: "Pace",
    PZ: "Power Zone",
    CA: "Cadence",
    HR: "Heart Rate"
  };

  function handleSetActivity(activity) {
    switch (activity) {
      case "WUP":
        return "Warmup";
      case "CD":
        return "CoolDown";
      case "RST":
        return "Rest";
      case "RC":
        return "Recover";
      case "PC":
        return "Pace";
      case "PZ":
        return "Power Zone";
      case "CA":
        return "Cadence";
      case "HR":
        return  "Heart Rate";

      default:
        return activity;
    }
  }

  // Check if stepsData and stepsData.steps are defined before rendering
  if (!stepsData || !stepsData.steps) {
    return null;
    // <div className="min-w-[20%] min-h-full overflow-hidden z-20">
    //   <div className="flex flex-col h-full">
    //     <div className="calendar_border bg-[#F0F4F8] w-full h-full p-2 pr-6"></div>
    //   </div>
    // </div> // we should  return some  UI if data is not available (ask Maam)
  }

  if (Props.click && Props.refId) {
    return (
      <>
        <div className="min-w-[20%] min-h-full overflow-hidden z-20">
          <div className="flex flex-col h-full">
            <div className="calendar_border bg-[#F0F4F8] w-full h-full p-2 pr-2">
              <div className="flex flex-col">
                <p className="text-right text-[var(--black)]">
                  {Props.trainigSliderDate}
                </p>
                <div className="flex items-center mt-2 text-[var(--black)] text-left text-[1.1rem]">
                  <div className="mr-1 ">
                    {stepsData.workout_type === "RUN" && (
                      <img className="h-4" src={RunIcon} alt="" />
                    )}
                    {stepsData.workout_type === "SWIM" && (
                      <img className="h-4" src={SwimICon} alt="" />
                    )}
                    {stepsData.workout_type === "BIKE" && (
                      <img className="h-4" src={BikeIcon} alt="" />
                    )}
                  </div>

                  <p>{stepsData.name}</p>
                  <span className="text-[var(--grey)]">#tof</span>
                </div>

                {/* Training Steps Start */}
                {stepsData.steps.map((steps, index) => {
                  const isLastObject = stepsData.steps.length;

                  return (
                    <div key={`repeatsssss--${index}`}>
                      <div className="flex flex-col w-full bg-[var(--white)] border_top_radius calendar_border mt-2 pb-2">
                        {steps.no_of_repeats > 1 ? (
                          <div className="flex items-center bg-[#d1f7f3]  mb-2 border_top_radius px-1">
                            <img className="h-3" src={RepeatIcon} alt="" />
                            <p>{steps.no_of_repeats}</p>
                          </div>
                        ) : null}

                        {/* {steps.no_of_repeats <= 1 && (
                          <>
                            {steps.sequence === 1 || isLastObject === 1 ? (
                              <div className="bg-[#FE5F55] py-[.2rem] mb-2 border_top_radius"></div>
                            ) : (
                              <></>
                            )}
                          </>
                        )} */}

                        {index + 1 === isLastObject &&
                          steps.sequence > 1 &&
                          steps.no_of_repeats <= 1 && (
                            <div className="bg-[#bfd200] py-[.2rem] mb-2 border_top_radius"></div>
                          )}

                        {/* Use this map for card info in the box e.g., wup, z1, time, distance */}
                        {steps.activity_steps &&
                          steps.activity_steps.map(
                            (ActivitySteps, ActivityStepsIndex) => {
                              const formattedActivity = handleSetActivity(
                                ActivitySteps.activity
                              );

                              let res;
                              if (
                                ActivitySteps.time &&
                                ActivitySteps.time.includes(":")
                              ) {
                                res = formatTime(ActivitySteps.time);
                              }

                              return (
                                <>
                                  {/* Card heading color */}

                                  {ActivitySteps.activity === "WUP" || steps.sequence === 1 ? <div className="bg-[#FE5F55] py-[.2rem] mb-2 border_top_radius"></div> : <></>}
                                  
                                  {steps.sequence > 1 &&
                                  steps.no_of_repeats === 1 &&
                                  index + 1 !== isLastObject  && ActivitySteps.activity != "WUP" ? (
                                    <div className="bg-[#b2f7ef] py-[.2rem] mb-2 border_top_radius"></div>
                                  ) : (
                                    <></>
                                  )}

                                  {/* Workout steps  */}
                                  <div
                                    key={`activeStepss-${ActivityStepsIndex}`}
                                    className="flex justify-between w-full px-1 text-[var(--blue)] font-medium"
                                  >
                                    <span>
                                      {formattedActivity ||
                                        ActivitySteps.activity}
                                    </span>

                                    {/* Ensure to safely access properties */}
                                    <span>
                                      {(targetMapping[ActivitySteps.primaryTarget?.target]  ?? "") + " " + (ActivitySteps.primaryTarget?.value ?? "")}
                                    </span>

                                    {/* Distance and time */}
                                    <div className="flex flex-col items-end">
                                      {ActivitySteps.time && (
                                        <span>
                                          {res || ActivitySteps.time || ""}
                                        </span>
                                      )}
                                      {ActivitySteps.distance > 0 && (
                                        <>
                                          <span className="text-right">
                                            {ActivitySteps.distance} m
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                })}
                {/* Training Steps End */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null; // Return null if Props.click and Props.refId are not defined
}

export default TrainingSlider;

import React, { useState, useEffect, useContext } from "react";
import EmailIcon from "assets/icons/drafts.png";
import PhoneIcon from "assets/icons/call.png";
import LocationIcon from "assets/icons/add_location.png";
import RunIcon from "assets/icons/man_runinng.svg";
import Tag from "assets/icons/Tag.png";
import User from "assets/icons/person.png";
import { updateForm } from "state/SetCoachFormData";
import { SubmitCoachFormData } from "utility/constants/useContext";
import WatchIcon from "assets/icons/calendar_today.svg";
import { useSelector } from "react-redux";
function PhoneFormStep1(props) {
  const { isValidEmail, setIsValidEmail } = props;

  const Props = useContext(SubmitCoachFormData);
  const data = useSelector((state) => state.viewAccount);

  useEffect(() => {
    if (Props.gender === "M") {
      Props.setGender("Male");
    } else if (Props.gender === "F") {
      Props.setGender("Female");
    } else if (Props.gender === "T") {
      Props.setGender("Trans");
    }
  }, [Props.gender]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Email validation logic

  const handleEmail = (event) => {
    const { value } = event.target;
    Props.setEmail(value);
    // Validate email using regular expression
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsValidEmail(isValid);
  };

  //Phone Validation Logic
  const [isValidNumber, setIsValidNumber] = useState(true);
  const handleNumber = (event) => {
    const { value } = event.target;

    // Check if the value is numeric and has a length of 10 or fewer digits
    if (/^\d{0,10}$/.test(value)) {
      Props.dispatch(updateForm({ number: value }));

      // Validate if the number is exactly 10 digits long
      if (value.length === 10) {
        setIsValidNumber(true);
      } else {
        setIsValidNumber(false);
      }
    }
  };

  const currentYear = new Date().getFullYear();

  const dateString = data.dob; // Example date in DD-MM-YYYY format

  // Split the string by the "-" delimiter and extract the year (third part)
  const dateParts = dateString ? dateString.split("-") : [];

  // The year will be the third element in the array
  const year = dateParts[2] ? dateParts[2] : currentYear - 10;

  const yearLength = [currentYear - year] - 9;

  const years = Array.from({ length: yearLength }, (_, i) => currentYear - i);

  return (
    <>
      <div className="w-[90%]  mx-auto pt-16 pb-16 phone_step1 ">
        <div className="">
          <div className="location">
            <label htmlFor="input" className="text">
              Name
              {Props.formState.name === "" && (
                <sup className="text-[var(--red)]"> *</sup>
              )}
            </label>
            <div className="flex items-center">
              <img src={User} className="mr-4" alt="" />
              <input
                type="text"
                placeholder="Name"
                name="input"
                className="input"
                value={Props.formState.name}
                onChange={(e) =>
                  Props.dispatch(updateForm({ name: e.target.value }))
                }
                required
              />
            </div>
          </div>

          <div className="location">
            <label htmlFor="input" className="text">
              Tagline
              {Props.formState.tagline === "" && (
                <sup className="text-[var(--red)]"> *</sup>
              )}
            </label>
            <div className="flex items-center">
              <img src={Tag} className="mr-4" alt="" />
              <input
                type="text"
                placeholder=" Tagline "
                name="input"
                className="input"
                value={Props.formState.tagline}
                onChange={(e) =>
                  Props.dispatch(updateForm({ tagline: e.target.value }))
                }
                required
              />
            </div>
          </div>
          <div className="email  ">
            <label htmlFor="input" className="email z-10">
              Email
            </label>
            <div className="flex items-center">
              <img src={EmailIcon} className="mr-4" alt="" />
              <input
                type="email"
                placeholder=""
                name="email"
                value={Props.email}
                className="input"
                disabled={true}
                onChange={handleEmail}
                required
              />
              {isValidEmail ? (
                <></>
              ) : (
                <div>
                  {" "}
                  <p className="text-[var(--red)] text-right mr-[15%]">
                    invalid email
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="number">
            <label htmlFor="input" className="text">
              Phone Number
              {Props.formState.number === "" && (
                <sup className="text-[var(--red)]"> *</sup>
              )}
            </label>
            <div className="flex items-center">
              <img src={PhoneIcon} className="mr-4" alt="" />
              <input
                type="text"
                placeholder=""
                name="number"
                className="input"
                value={Props.formState.number}
                maxLength={10}
                onChange={handleNumber}
                required
              />
            </div>
            {isValidNumber ? (
              <></>
            ) : (
              <div>
                {" "}
                <p className="text-[var(--red)] text-right absolute right-[15%]">
                  Invalid Number
                </p>
              </div>
            )}
          </div>

          <div className="location">
            <label htmlFor="input" className="text">
              Location
              {Props.formState.location === "" && (
                <sup className="text-[var(--red)]"> *</sup>
              )}
            </label>
            <div className="flex items-center">
              <img src={LocationIcon} className="mr-4" alt="" />
              <input
                type="text"
                placeholder=" City, State, Country "
                name="input"
                className="input"
                value={Props.formState.location}
                onChange={(e) =>
                  Props.dispatch(updateForm({ location: e.target.value }))
                }
                required
              />
            </div>
          </div>

          <div className="number mt-1">
            <label htmlFor="year" className="text !top-[0.6rem]">
              Coaching Since
              {Props.formState.coaching_since === "" && (
                <sup className="text-[var(--red)]"> *</sup>
              )}
            </label>
            <div className="flex items-center">
              <img src={WatchIcon} alt="" className="w-5 h-5 mr-4" />
              <select
                name="year"
                className="input max-h-24 "
                onChange={(e) => {
                  Props.dispatch(
                    updateForm({ coaching_since: e.target.value })
                  );
                }}
                value={Props.formState.coaching_since}
                required
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* athletes trained */}
          <div className="number mt-1">
            <label htmlFor="input" className="text !top-[0.6rem]">
              Athletes Trained
              {Props.formState.athtlete_trained === "" && (
                <sup className="text-[var(--red)]"> *</sup>
              )}
            </label>
            <div className="flex items-center">
              <img src={RunIcon} alt="" className="w-5 h-5 mr-4" />
              <input
                type="number"
                placeholder="How many atheletes you have trained"
                name="number"
                className="input"
                value={Props.formState.athtlete_trained}
                onChange={(e) => {
                  Props.dispatch(
                    updateForm({ athtlete_trained: e.target.value })
                  );
                }}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhoneFormStep1;

import React, { useEffect, useState } from "react";
import { EditViewAccount } from "api/UserAccountController";
import { useSelector, useDispatch } from "react-redux";
import { addViewAccountData } from "state/ViewAccountState";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";

function Aboutus(props) {
  const dispatch = useDispatch();
  const { setEditAbout, editProfile, editSetting, about, setAbout } = props;

  const data = useSelector((state) => state.viewAccount);
  const [showMore, setShowMore] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const [editAoutDisable, setEditAboutDisable] = useState(false);
  const [gender, setGender] = useState("");

  useEffect(() => {
    if (editProfile || editSetting) {
      setEditAboutDisable(true);
    } else {
      setEditAboutDisable(false);
    }

    setAbout(about);
    if (about === undefined || null) {
      setAbout("");
    }

    if (data.gender === "M" || "Male") {
      setGender("Male");
    } else if (data.gender === "F" || "Female") {
      setGender("Female");
    } else if (data.gender === "T" || "Trans") {
      setGender("Trans");
    }
  }, [editProfile, editSetting, isEdit]);

  // useEffect(() => {}, [isEdit, editProfile, editSetting]);

  // about us data
  const text = `${data.about}`;

  // show more btn logic
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  async function handleSave() {
    // if (about === "") {
    //   return;
    // } else {
    const res = await EditViewAccount(
      data.name,
      data.mobile,
      data.athlete_type,
      data.dob,
      gender,
      data.location,
      about
    );

    if (res === true) {
      dispatch(addViewAccountData({ about: about }));
      setEdit(false);
      setEditAbout(false);
    }
    // }
  }

  function handleAbout(e, delta, source, editor) {
    setAbout(e);

    // Use unprivileged editor methods
    const plainText = editor.getText(); // Get plain text (without HTML tags)
    const html = editor.getHTML(); // Get full HTML content
    const length = editor.getLength(); // Get character count (excluding HTML tags)
  }

  return (
    <>
      <div className={`setting_box ${isEdit ? "edit_mode" : ""}`}>
        <div className="profile_box_heading_div">
          <h2>About</h2>
          {isEdit ? (
            <div className="flex">
              <p
                className="cancelButton"
                onClick={() => {
                  setEdit(false);
                  setEditAbout(false);
                }}
              >
                Cancel
              </p>
              <p className="saveButton" onClick={handleSave}>
                Save
              </p>
            </div>
          ) : (
            <button
              disabled={editAoutDisable}
              className="edit_button"
              onClick={() => {
                setEdit(true);
                setEditAbout(true);
              }}
            >
              Edit
            </button>
          )}
        </div>
        <div className="input_info_box">
          <div className="about_box">
            {isEdit ? (
              <ReactQuill theme="snow" value={about} onChange={handleAbout} />
            ) : (
              <>
                <div
                  className="max-h-[9rem] overflow-y-auto ql-editor  text-[var(--light-blue)]"
                  dangerouslySetInnerHTML={{ __html: about }}
                ></div>
                {/* <p className="max-h-[9rem] overflow-y-auto ql-editor text-[var(--blue)] text-[1rem] break-words">
                  {text.length > 100 ? (
                    <>
                      {" "}
                      {showMore ? text : `${text.substring(0, 80)}...`}
                      <span
                        className="text-[var(--link)] cursor-pointer text-sm"
                        onClick={handleShowMore}
                      >
                        <br />
                        {showMore ? " show less" : "show more"}
                      </span>
                    </>
                  ) : (
                    <>{data.about}</>
                  )}
                </p> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutus;

import React, { useContext } from "react";
import {
  eachDayOfInterval,
  endOfISOWeek,
  startOfISOWeek,
  format,
} from "date-fns";
import CalendarEvents from "./CalendarEvents";
import { CalendarData } from "utility/constants/useContext";

function PhoneView() {
  const currentDate = new Date();
  const Props = useContext(CalendarData);
  const EventData = Props.calendarData; // event date
  const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const firstDayOfWeek = startOfISOWeek(currentDate);
  const lastDayOfWeek = endOfISOWeek(currentDate);

  const daysInWeek = eachDayOfInterval({
    start: firstDayOfWeek,
    end: lastDayOfWeek,
  }); // Array of days in the current Week

  return (
    <div className="mt-[4rem]">
      <div className="bg-transparent flex flex-col border border-[#DBDBDB] border-solid ">
        <div className="">
          {daysInWeek.map((date, index) => {
            const dayOfWeekIndex = (date.getDay() + 6) % 7; // Adjust to start the week on Monday
            return (
              <div
                className={`flex text-left min-h-[8rem] m-0 text-[1.2rem]  border-[.5px] border-[#DBDBDB] border- ${
                  format(date, "d") === format(currentDate, "d")
                    ? "border-[1px] border-solid border-[var(--blue)]"
                    : ""
                }`}
                key={`date-${index}`}
              >
                <div className="flex flex-col bg-[#EAEAEA]  text-[var(--blue)] w-[3.2rem] items-center justify-center mr-2">
                  <p className="">{format(date, "MMM")}</p>
                  <p className="font-bold text-[1.4rem]">
                    {format(date, "dd")}
                  </p>{" "}
                  {/* Use "d" for single-digit day */}
                  <p className="">{weekdays[dayOfWeekIndex]}</p>
                </div>
                <div></div>

                <div className="flex flex-col items-start w-full justify-center px-4">
                  {/*--------- EVENTS --------*/}
                  {EventData.length === 0 ? (
                    <></>
                  ) : (
                    <CalendarEvents calendarDay={format(date, "dd-MM-yyyy")} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PhoneView;

import React, { useState, useEffect } from "react";
import Certificed from "assets/icons/certified.svg";
import { viewCoachesData } from "../data";
import FourStar from "assets/icons/four_star.svg";
import { Link } from "react-router-dom";
import Arows from "assets/icons/Group.svg";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";

function ViewCoachCardPhone({ selectedActivity }) {
  const [coachList, setCoachList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const selectedActivityString =
    selectedActivity && selectedActivity.length > 0
      ? selectedActivity.join(",")
      : null;
  const apiUrl = `${
    API_URL_CONSTANTS.COACHES_LIST
  }?pageNumber=${pageNumber}&pageSize=${pageSize}${
    selectedActivityString ? `&categories=${selectedActivityString}` : ""
  }`;
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchCoachList = async () => {
      const res = await getApis(apiUrl);
      if (res && !res.error) {
        if (res.data && res.data.content) {
          setCoachList(res.data.content);
        } else {
        }
      }
    };
    fetchCoachList();
  }, [apiUrl]);
  return (
    <div>
      <div className="coach_card_phone ">
        {coachList.map((data) => (
          <div
            key={data.refId}
            className="bg-[var(--white)] w-[95%] mx-auto my-4 shadow p-2 "
          >
            {/* Certificate div Start*/}
            {/* <div className="flex ">
              <div className="flex flex-col pl-[1rem]">
                <div className="flex items-center">
                  <img className="h-[1rem]" src={Certificed} alt="" />
                  <h6 className="text-[var(--blue)] font-normal">
                    {data.certificate[0].name}
                  </h6>
                </div>
                <div>
                  <p className="text-[0.6rem] mt-[-0.2rem]">
                    ({data.certificate[0].date})
                  </p>
                </div>
              </div>
              <div className="flex flex-col pl-[1rem]">
                <div className="flex items-center">
                  <img className="h-[1rem]" src={Certificed} alt="" />
                  <h6 className="text-[var(--blue)] font-normal">
                    {data.certificate[1].name}
                  </h6>
                </div>
                <div>
                  <p className="text-[0.6rem] mt-[-0.2rem]">
                    ({data.certificate[1].date})
                  </p>
                </div>
              </div>
            </div> */}
            {/* Certificate div End*/}

            {/* card body start */}
            <div className="flex flex-col  pt-4 px-4">
              <div className="flex  items-start py-2 ">
                <img
                  className="rounded-[50%] w-[8rem] h-[8rem] object-cover"
                  src={`${API_URL_CONSTANTS.DOMAIN_NAME}${data.img}`}
                  alt=""
                />
                <div className="flex flex-col pl-[1rem] justify-center">
                  <h6 className="text-[1.3rem] text-[var(--blue)]  font-normal pt-2">
                    {data.name}
                  </h6>

                  <span className="text-[.7rem]">
                    ( Coaching Since {data.coaching_since} )
                  </span>
                  <img className="pt-1 w-[5rem]" src={FourStar} alt="" />
                  <span className="text-[.7rem]">
                    ( Athlete trained {data.athlete_trained} )
                  </span>
                </div>
              </div>
              <div className="flex flex-col p-2">
                <div
                  className="text-[1.1rem] text-[var(--light-blue)] leading-7 tracking-wide break-words ql-editor list-disc list-inside"
                  dangerouslySetInnerHTML={{ __html: data.about }}
                />
                <div className="flex  pt-[2rem] justify-between">
                  <div>
                    <h6
                      className="text-[var(--blue)] text-[1.2rem] font-normal pb-2
                  "
                    >
                      Coaches :
                    </h6>
                    <ul className="viewCoah_li flex flex-col text-[1.2rem]">
                      {data.coaches?.map((coach, index) => (
                        <li key={index}>
                          {coach.category.charAt(0).toUpperCase() +
                            coach.category.slice(1)}
                          : {coach.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <div className="pt-8 ">
                      <p className="text-[var(--blue)] flex text-[1rem] md:grid sm:grid lg:grid xl:grid">
                        To View Profile
                        <div className="flex ">
                          <Link
                            to="/coachdetails"
                            state={data.refId}
                            className=" text-[0.9rem]  text-[#007c3c] sm:ml-0 md:ml-0 xl:ml-0 mt-[2px]"
                          >
                            Click Here
                          </Link>
                          <img
                            src={Arows}
                            className="w-3 h-3  ml-1 mt-[6px]"
                          ></img>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ViewCoachCardPhone;

import React, { useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLogin from "components/signin/PopUpLogin";
import SEO from "hooks/Seo";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import SignUpPopUp from "components/signup/SignUpPopUp";
import ViewCoachCard from "./viewCoachComponents/ViewCoachCard";
import ViewCoachCardPhone from "./viewCoachComponents/ViewCoachCardPhone";
import Run from "assets/icons/run.svg";
import Steps from "assets/icons/steps.svg";
import Cycle from "assets/icons/cycle.svg";
import Tri from "assets/icons/tri.svg";
import Swim from "assets/icons/swim.svg";
import Pool from "assets/icons/pool.svg";
import Bike from "assets/icons/bike.svg";
import Triathlon from "assets/icons/triathlon.svg";
import FindUser from "assets/icons/FindUser.svg";
import blueArrow from "assets/icons/blueArrow.svg";
import OrgSteps from "assets/icons/orgSteps.svg";
import OrgCycle from "assets/icons/orgCycle.svg";
import OrgPool from "assets/icons/orgPool.svg";
import OrgTri from "assets/icons/orgTri.svg";
function ViewCoaches() {
  const [isSignUp, setSignUp] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [isForgotPassword, setForgotPassword] = useState(false);

  const toggleActivity = (activity) => {
    if (selectedActivities.includes(activity)) {
      setSelectedActivities(selectedActivities.filter((item) => item !== activity));
    } else {
      setSelectedActivities([...selectedActivities, activity]);
    }
  };

  const clearAllActivities = () => {
    setSelectedActivities([]);
  };

  // Function to map the full activity names to their respective short forms
  const mapToShortForm = (activities) => {
    return activities.map((activity) => {
      switch (activity) {
        case "Triathlon":
          return "TRI";
        case "Cycling":
          return "BIKE";
        case "Swimming":
          return "SWIM";
        case "Running":
          return "RUN";
        default:
          return activity;
      }
    });
  };

  return (
    <HelmetProvider>
      <Nav setSignUp={setSignUp} isSignUp={isSignUp} />
      <PhoneNavBar />
      <PopUpLogin isSignUp={isSignUp} setSignUp={setSignUp} />
      <SignUpPopUp setSignUp={setSignUp} isSignUp={isSignUp} />
      <PopUpLoginP
        isForgotPassword={isForgotPassword}
        setForgotPassword={setForgotPassword}
        isSignUp={isSignUp}
        setSignUp={setSignUp}
      />
      <SEO
        title="Search for Coach | Tackofit"
        descriptionName="Athlete"
        description="Get expert help every step of the way. Connect with an accredited coach tailored to your needs or explore our coach directory."
        canonicalUrl="https://www.trackofit.com/become-a-coach"
      />
      <div className="bg-[var(--light-grey)] min-h-screen">
        <div className="width_70_div bg-[#f6f6f6] min-h-screen">
          <h1 className="text-[var(--blue)] text-[2.5rem] text-center font-normal pt-[1rem]">
            Look For Best Coaches
          </h1>
          <div className="flex justify-center text-[1rem] py-4 flex-wrap sm:grid sm:grid-cols-2 sm:gap-3 sm:justify-between">
            <div
              className={`fliter_btn ${selectedActivities.includes("Triathlon") ? "selected_fliter_btn" : ""} sm:flex-1 text-center`}
              onClick={() => toggleActivity("Triathlon")}
            >
              <img src={selectedActivities.includes("Triathlon") ? Tri : Triathlon} alt="Triathlon" />
              <span>Triathlon</span>
            </div>

            <div
              className={`fliter_btn ${selectedActivities.includes("Running") ? "selected_fliter_btn" : ""} sm:flex-1 text-center`}
              onClick={() => toggleActivity("Running")}
            >
              <img src={selectedActivities.includes("Running") ? Run : Steps} alt="Running" />
              <span>Running</span>
            </div>

            <div
              className={`fliter_btn ${selectedActivities.includes("Cycling") ? "selected_fliter_btn" : ""} sm:flex-1 text-center`}
              onClick={() => toggleActivity("Cycling")}
            >
              <img src={selectedActivities.includes("Cycling") ? Cycle : Bike} alt="Cycling" />
              <span>Cycling</span>
            </div>

            <div
              className={`fliter_btn ${selectedActivities.includes("Swimming") ? "selected_fliter_btn" : ""} sm:flex-1 text-center`}
              onClick={() => toggleActivity("Swimming")}
            >
              <img src={selectedActivities.includes("Swimming") ? Pool : Swim} alt="Swimming" />
              <span>Swimming</span>
            </div>

            <div className="sm:col-span-2 sm:flex sm:justify-end ">
              <div className="selected_fliter_btn bg-[var(--dark-orange)] w-24 justify-center" onClick={clearAllActivities}>
                Reset
              </div>
            </div>
          </div>

        {selectedActivities.length > 0 && (
          <div className="flex items-center">
            <div className="find_btn flex items-center sm:p-0">
              <img src={FindUser} alt="FindUser" />
              <span>Results for </span>
              <img src={blueArrow} alt="blueArrow" className="ml-3" />

              {selectedActivities.map((activity, index) => (
                <div key={index} className="flex items-center ml-2 flex-wrap ">
                  <img
                    src={
                      activity === "Triathlon"
                        ? OrgTri
                        : activity === "Cycling"
                        ? OrgCycle
                        : activity === "Swimming"
                        ? OrgPool
                        : activity === "Running"
                        ? OrgSteps
                        : ""
                    }
                    alt={activity}
                    className="mr-2"
                  />
                  <span className="text-[#FB8500]">{activity}</span>
                </div>
              ))}
            </div>
          </div>
        )}

          <ViewCoachCard selectedActivity={mapToShortForm(selectedActivities)} />
          <ViewCoachCardPhone selectedActivity={mapToShortForm(selectedActivities)} />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default ViewCoaches;

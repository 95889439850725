import React, { useContext, useEffect, useState } from "react";
import show from "assets/icons/OpenEye.svg";
import nonShow from "assets/icons/CloseEye.svg";
import { SubmitData } from "api/Signup";
import { Button } from "rsuite";
import ForgorPasswordPopUp from "components/forgetPassword/ForgotPasswordPopUp";
import "rsuite/dist/styles/rsuite-default.min.css";
import PasswordInput from "components/inputs/PasswordInput";
import EmailInput from "components/inputs/EmailInput";
import { AppProps } from "utility/constants/useContext";
function PopUpLogin(props) {
  const { isSignUp, setSignUp } = props;
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const [passwordError, setPasswordError] = useState(""); // gives any error message for password
  const [fillEmail, setFillEmail] = useState(""); // gives any error message when email is empty
  const [fillPassword, setFillPassword] = useState(""); // gives any error message when password is empty
  const [isErrorPopup, setErrorPopup] = useState("");
  const AppData = useContext(AppProps);

  // if the signin popup is closed clear the DATA
  useEffect(() => {
    if (!AppData.isOpen) {
      setEmail("");
      setPassword("");
    }
  }, [AppData.isOpen]);

  // Making invalid email and password error display non
  useEffect(() => {
    if (email === "" || password === "") {
      setErrorPopup("");
    }
  }, [email, password]);

  // -------------- password VALIDATION ----------------
  function validatePassword(password) {
    const passwordRegex = /^(?=.*[!@#$])[a-zA-Z0-9!@#$]{6,12}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Enter a 6 to 12 character password (a-z, A-Z, 0-9, !@#$) with at least one special character."
      );
    } else {
      setPasswordError("");
    }
  }

  // -------------- Email VALIDATION ----------------
  function validateEmail(email) {
    const emailRegex = /^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;

    if (!emailRegex.test(email)) {
      setValidEmail("Enter a valid email");
    } else {
      setValidEmail("");
    }
  }

  //----------------- SUBMIT --------------

  async function handleSubmit(e) {
    e.preventDefault();
    if (email === "" || password === "") {
      if (email === "") {
        setFillEmail("Fill up email");
      } else if (password === "") {
        setFillPassword("Fill up password");
      }
    } else {
      if (load === true) {
        return;
      }
      setLoad(true);
      const req = await SubmitData(email, password, AppData.RememberMe, e);
      if (req === true) {
        setLoad(false);
        AppData.setIsSignedIn(true);
        AppData.setOpen(false);
      } else if (req.status_code === 2) {
        setLoad(false);
        setErrorPopup(req.message);
      }
    }
  }

  return (
    <>
      <div className={`slider ${AppData.isOpen ? "visible" : ""}`}>
        {!isForgotPassword ? (
          <>
            <form onSubmit={handleSubmit} className=" flex flex-col ">
              <div className="desktop_input_div">
                <div className="margin_top_8">
                  <EmailInput
                    email={email}
                    setEmail={setEmail}
                    validateEmail={validateEmail}
                  />
                </div>

                {fillEmail && (
                  <div className="error_message text-right">{fillEmail}</div>
                )}
                {validEmail && (
                  <div className="error_message text-right">{validEmail}</div>
                )}
                <div className="relative">
                  <div className="margin_top_8">
                    <PasswordInput
                      password={password}
                      setPassword={setPassword}
                      showPassword={showPassword}
                      validatePassword={validatePassword}
                    />
                  </div>
                  <label
                    className="show_password"
                    htmlFor=""
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <img src={showPassword ? show : nonShow} alt="" />
                  </label>
                </div>
              </div>
              {passwordError && (
                <div className="error_message text-left">{passwordError}</div>
              )}
              {fillPassword && (
                <div className="error_message text-right">{fillPassword}</div>
              )}
              <div className="error_message text-right">{isErrorPopup}</div>

              {/*================== CHECKBOX =====================*/}

              <div className="checkbox_div flex items-center justify-between w-[90%] text-[0.7rem] mx-auto my-1">
                <div className="flex_center_div">
                  <input
                    className="mt-[-0.1rem]"
                    type="checkbox"
                    onChange={() => AppData.setRememberMe(!AppData.RememberMe)}
                  />
                  <span className="ml-[0.2rem]">Remember me</span>
                </div>
                <div>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setForgotPassword(!isForgotPassword);
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              {/*================ SUBMIT BUTTON ==============*/}

              <div className="flex_center_div p-[.55rem]">
                {load ? (
                  <div className="orange_btn">
                    <Button
                      loading
                      className="submit_box bg-[var(--orange)]"
                    ></Button>
                  </div>
                ) : (
                  <button className="orange_btn">Sign In</button>
                )}
              </div>
              <div className="w-full text-center p-[0.8rem] bg-[#f4f4f4]">
                <p
                  className="text-[0.9rem]"
                  onClick={() => {
                    setSignUp(!isSignUp);
                    AppData.setOpen(false);
                  }}
                >
                  Don't have a account?{" "}
                  <span className="text-[var(--link)] cursor-pointer">
                    Sign up
                  </span>
                </p>
              </div>
            </form>
          </>
        ) : (
          <div className="forgotPassword_desktop">
            <ForgorPasswordPopUp setForgotPassword={setForgotPassword} />
          </div>
        )}

        {/*============ CLOSE POPUP BACKGROUND ============*/}
        {AppData.isOpen && (
          <div
            onClick={() => AppData.setOpen(false)}
            className="desktop_popup_background"
          ></div>
        )}
      </div>
      {/*------------------- SIGN UP POPUP -----------------------*/}
    </>
  );
}

export default PopUpLogin;

import React, { useContext, useEffect, useState } from "react";
import RightArrow from "assets/icons/right-arrow.png";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import BackArrow from "assets/icons/back.png";
import { updateForm } from "state/SetCoachFormData";
import FormStep5 from "./FormStep5";
import FormStep7 from "./FormStep7";

function FormStep6() {
  const Props = useContext(SubmitCoachFormData);

  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [popper, setPopper] = useState(false);
  const navigate = useNavigate();

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    setBackClicked(true);
  };

  useEffect(() => {
    if (Props.formState.languages.length === 0) {
      setValidation(false);
    } else {
      setValidation(true);
    }
  });
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun = Props.formState.languages.includes(
      value
    )
      ? Props.formState.languages.filter((item) => item !== value)
      : [...Props.formState.languages, value];

    Props.dispatch(updateForm({ languages: updatedSelectedDistancesRun }));
  };

  return (
    <div>
      {backClicked ? (
        <FormStep5 />
      ) : (
        <>
          {nextClicked && validation ? (
            <FormStep7 />
          ) : (
            <div className="coach_form">
              <div className="form_section">
                <form action="" method="post" className="mt-16 m-0">
                  <div className="step2_div">
                    <div className="question_1 ">
                      <h4>Language You Know </h4>
                    </div>

                    <div className="question_2">
                      <div className="yes_no_div swim pb-[6rem]">
                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            value="English"
                            onChange={() => handleCheckboxChange("English")}
                            checked={Props.formState.languages.includes(
                              "English"
                            )}
                          />
                          <span>English</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Hindi"
                            onChange={() => handleCheckboxChange("Hindi")}
                            checked={Props.formState.languages.includes(
                              "Hindi"
                            )}
                          />
                          <span>Hindi</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Marathi"
                            onChange={() => handleCheckboxChange("Marathi")}
                            checked={Props.formState.languages.includes(
                              "Marathi"
                            )}
                          />
                          <span>Marathi</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Telugu"
                            onChange={() => handleCheckboxChange("Telugu")}
                            checked={Props.formState.languages.includes(
                              "Telugu"
                            )}
                          />
                          <span>Telugu</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Tamil"
                            onChange={() => handleCheckboxChange("Tamil")}
                            checked={Props.formState.languages.includes(
                              "Tamil"
                            )}
                          />
                          <span>Tamil</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Gujarati"
                            onChange={() => handleCheckboxChange("Gujarati")}
                            checked={Props.formState.languages.includes(
                              "Gujarati"
                            )}
                          />
                          <span>Gujarati</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Bengali"
                            onChange={() => handleCheckboxChange("Bengali")}
                            checked={Props.formState.languages.includes(
                              "Bengali"
                            )}
                          />
                          <span>Bengali</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Kannada"
                            onChange={() => handleCheckboxChange("Kannada")}
                            checked={Props.formState.languages.includes(
                              "Kannada"
                            )}
                          />
                          <span>Kannada</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Malayalam"
                            onChange={() => handleCheckboxChange("Malayalam")}
                            checked={Props.formState.languages.includes(
                              "Malayalam"
                            )}
                          />
                          <span>Malayalam</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Punjabi"
                            onChange={() => handleCheckboxChange("Punjabi")}
                            checked={Props.formState.languages.includes(
                              "Punjabi"
                            )}
                          />
                          <span>Punjabi</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Urdu"
                            onChange={() => handleCheckboxChange("Urdu")}
                            checked={Props.formState.languages.includes("Urdu")}
                          />
                          <span>Urdu</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="Spanish"
                            onChange={() => handleCheckboxChange("Spanish")}
                            checked={Props.formState.languages.includes(
                              "Spanish"
                            )}
                          />
                          <span>Spanish</span>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="radio"
                            id=""
                            value="French"
                            onChange={() => handleCheckboxChange("French")}
                            checked={Props.formState.languages.includes(
                              "French"
                            )}
                          />
                          <span>French</span>
                        </label>
                      </div>
                    </div>

                    <div className="statusBar">
                      {popper && (
                        <div className="popper absolute bottom-12 right-1">
                          <p className="text-[var(--red)]">
                            fill up the fileds
                          </p>
                        </div>
                      )}

                      <div className="next_btn statusBar">
                        <div className="flex justify-between">
                          <div
                            className="mr-[10px] mt-[10px] cancelButton"
                            onClick={handleBackClick}
                          >
                            <div className=" flex items-center">
                              <img
                                src={BackArrow}
                                alt=""
                                className="next_img mr-1 ml-[-3px]"
                              />
                              Back
                            </div>
                          </div>
                          <div className="coach_cancel_Div flex">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={() => {
                                navigate("/");
                              }}
                            >
                              Cancel
                            </p>
                            <div
                              className="nextButton"
                              onClick={() => {
                                if (validation === true) {
                                  setNextClicked(true);
                                  setPopper(false);
                                } else {
                                  if (validation === false) {
                                    setPopper(true);
                                  }
                                }
                              }}
                            >
                              Next{" "}
                              <img
                                src={RightArrow}
                                alt=""
                                className="next_img mt-[6px]"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FormStep6;

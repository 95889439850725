import React, { useState, useEffect } from "react";
import { getFormData } from "api/CoachFormController";
import { SubmitCoachForm } from "api/CoachFormController";
import EditFormYouTrain from "../coachFormComponents/EditFormYouTrain";
import EditPhoto from "../coachFormComponents/EditPhotos";
import EditVideo from "../coachFormComponents/EditVideo";
import { Link } from "react-router-dom";
import ErrorPopup from "components/popups/ErrorPopup";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants";
import Certificate from "pages/accountPage/accountPageComponents/Certificate";
import { useDispatch, useSelector } from "react-redux";
import {
  addCoachFormData,
  CoachFormData,
  initialState,
} from "state/CoachFormData";
import { isDataEmpty } from "utility/utility_functions/isDataEmpty";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { getApis } from "hooks/api";
import ReactQuill from "react-quill";

function EditCoachForm() {
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.getCoachFromData);
  const [getData, setData] = useState([]);
  const [CoverImgSrc, setCoverImgSrc] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [gender, setGender] = useState("");
  const [run, setRun] = useState([]);
  const [swim, setSwim] = useState([]);
  const [bike, setBike] = useState([]);
  const [triathlon, setTriathlon] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [coverPhotoPath, setCoverPhotoPath] = useState(null);
  const [profilePhotoPath, setProfilePhotoPath] = useState(null);
  const [videoPath, setVideoPath] = useState(null);
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [editSetting, setEditSetting] = useState(false);
  const [editAbout, setEditAbout] = useState(false);
  const [editProfileDisable, setEditProfileDisable] = useState(false);
  const [showMore, setShowMore] = useState(false);

  // about us data
  const text = `${Data.about}`;

  const coaching_since = getData.coaching_since ? getData.coaching_since : "";
  const athtlete_trained = getData.athtlete_trained;
  // ? getData.athtlete_trained
  // : "";

  const languages = getData.languages;
  // ? getData.languages
  // : ["english", "hindi"];

  const athlete_level = getData.athlete_level;
  // ? getData.athlete_level
  // : ["pro", "am"];

  const TAC = true;
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //API call get data for coach Form
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getFormData();

        if (res.data.cover_photo) {
          setCoverImgSrc(res.data.cover_photo);
        } else {
          const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);
          setCoverImgSrc(resCoverPhoto.data.message);
        }
        if (res.data.profile_photo) {
          setProfileImgSrc(res.data.profile_photo);
        } else {
          const resProfilePhoto = await getApis(
            API_URL_CONSTANTS.GET_PROFILE_IMG
          );
          setProfileImgSrc(resProfilePhoto.data.message);
        }
        if (res.data.video) {
          // const resGetVideo = await getVideo();

          setVideoUrl(res.data.video);
        }

        // dispatch(CoachFormData({ getCoachFromData: res.data }));
        dispatch(addCoachFormData({ initialState: res.data }));

        setData(res.data);
        setCertificates(res.data.certifications || []);
        if (res.data && res.data.coaches.length > 0) {
          const valueString = res.data.coaches[0].value || "";
          const sanitizedString = valueString.replace(/,/g, "");
          const valueArray = sanitizedString.split(" ");
          setRun(valueArray);
        }
        if (res.data && res.data.coaches.length > 0) {
          const valueString = res.data.coaches[1].value || "";
          const sanitizedString = valueString.replace(/,/g, "");
          const valueArray = sanitizedString.split(" ");
          setSwim(valueArray);
        }
        if (res.data && res.data.coaches.length > 0) {
          const valueString = res.data.coaches[2].value || "";
          const sanitizedString = valueString.replace(/,/g, "");
          const valueArray = sanitizedString.split(" ");
          setBike(valueArray);
        }
        if (res.data && res.data.coaches.length > 0) {
          const valueString = res.data.coaches[3].value || "";
          const sanitizedString = valueString.replace(/,/g, "");
          const valueArray = sanitizedString.split(" ");
          setTriathlon(valueArray);
        }
      } catch (error) {
        return error;
      }
    };
    // if (isDataEmpty(Data, initialState)) {
    fetchData();
    //   console.log("api call");
    // } else {
    //   console.log("no Api call");
    // }
  }, []);

  function convertArray_String() {
    let StringBike = bike.length === 0 ? "" : bike.join(", ");

    let StringRun = run.length === 0 ? "" : run.join(", ");

    let StringSwim = swim.length === 0 ? "" : swim.join(", ");

    let StringTriathlon = triathlon.length === 0 ? "" : triathlon.join(", ");

    return {
      StringBike,
      StringRun,
      StringSwim,
      StringTriathlon,
    };
  }

  async function handleSubmit() {
    const { StringBike, StringRun, StringSwim, StringTriathlon } =
      convertArray_String();

    if (
      Data.name === "" ||
      Data.tagline === "" ||
      Data.phone === "" ||
      Data.location === "" ||
      Data.gender === "" ||
      Data.about === ""
    ) {
      setErrorHeader("Error");
      setErrorMessage(
        "Fill up Name, Tagline, Phone Number, Location, Gender, About"
      );
      setErrorPopup(true);
    } else {
      try {
        const req = await SubmitCoachForm(
          Data.name,
          Data.tagline,
          Data.email,
          Data.phone,
          Data.location,
          Data.gender,
          Data.about,
          StringRun,
          StringSwim,
          StringBike,
          StringTriathlon,
          coverPhotoPath,
          profilePhotoPath,
          videoPath,
          certificates,
          coaching_since,
          athtlete_trained,
          languages,
          athlete_level,
          TAC
        );

        if (req === true) {
          setEdit(false);
          setEditProfile(false);
        } else {
          setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
          setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
          setErrorPopup(true);
        }
      } catch (error) {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  async function handleVideo(videoPath) {
    const { StringBike, StringRun, StringSwim, StringTriathlon } =
      convertArray_String();
    if (
      Data.name === "" ||
      Data.tagline === "" ||
      Data.phone === "" ||
      Data.location === "" ||
      Data.gender === "" ||
      Data.about === ""
    ) {
      setErrorHeader("Error");
      setErrorMessage(
        "Fill up Name, Tagline, Phone Number, Location, Gender, About"
      );
      setErrorPopup(true);
    } else {
      try {
        const req = await SubmitCoachForm(
          Data.name,
          Data.tagline,
          Data.email,
          Data.phone,
          Data.location,
          Data.gender,
          Data.about,
          StringRun,
          StringSwim,
          StringBike,
          StringTriathlon,
          coverPhotoPath,
          profilePhotoPath,
          videoPath,
          certificates,
          coaching_since,
          athtlete_trained,
          languages,
          athlete_level,
          TAC
        );
        if (req === true) {
          window.location.reload(true);
        } else {
          setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
          setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
          setErrorPopup(true);
        }
      } catch (error) {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  const handleNumber = (e) => {
    const value = e.target.value;
    if (value.length <= 10 && /^\d*$/.test(value)) {
      // setPhone(value);
      dispatch(addCoachFormData({ phone: value }));
    }
  };

  function handleAbout(e, delta, source, editor) {
    // setAbout(e);

    dispatch(addCoachFormData({ about: e }));

    // Use unprivileged editor methods
    const plainText = editor.getText(); // Get plain text (without HTML tags)
    const html = editor.getHTML(); // Get full HTML content
    const length = editor.getLength(); // Get character count (excluding HTML tags)
  }

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {/* {getData ? <></> : <></>} */}
      <div className="coach_form">
        {getData === undefined ? (
          <div className="flex justify-center items-center h-[100vh]">
            <h3>No Data</h3>
          </div>
        ) : (
          <>
            <div className="form_section">
              {/* Cover Photo */}

              <EditPhoto
                gender={gender}
                CoverImgSrc={CoverImgSrc}
                profileImgSrc={profileImgSrc}
                setCoverPhotoPath={setCoverPhotoPath}
                setProfilePhotoPath={setProfilePhotoPath}
              />

              {/* Personal detials */}

              <div className={`mt-16 profile_box ${isEdit ? "edit_mode" : ""}`}>
                <div className="profile_box_heading_div">
                  <h2>Personal Details</h2>
                  {isEdit ? (
                    <div className="flex">
                      <p
                        className="cancelButton"
                        onClick={() => {
                          setEdit(false);
                          setEditProfile(false);
                        }}
                      >
                        Cancel
                      </p>
                      <p className="saveButton" onClick={handleSubmit}>
                        Save
                      </p>
                    </div>
                  ) : (
                    <button
                      className="edit_button m-0 ml-[0.3rem]"
                      disabled={editProfileDisable}
                      onClick={() => {
                        setEdit(true);
                        setEditProfile(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="input_info_box input_info_box_profile  input_info_edit">
                  <div className="first_half">
                    <p className="lable_tag">Name</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={Data.name}
                        onChange={(e) =>
                          dispatch(addCoachFormData({ name: e.target.value }))
                        }
                      />
                    ) : (
                      <p className="lable_value ">{Data.name}</p>
                    )}
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Tagline</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={Data.tagline}
                        onChange={(e) =>
                          dispatch(
                            addCoachFormData({ tagline: e.target.value })
                          )
                        }
                      />
                    ) : (
                      <p className="lable_value ">{Data.tagline}</p>
                    )}
                  </div>
                </div>
                <div className="input_info_box input_info_box_profile  input_info_edit">
                  <div className="first_half">
                    <p className="lable_tag">Phone</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={Data.phone}
                        maxLength={10}
                        onChange={handleNumber}
                      />
                    ) : (
                      <p className="lable_value ">{Data.phone}</p>
                    )}
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Email</p>
                    <p className="lable_value ">
                      {" "}
                      {Data.email === undefined ? (
                        <span>&ndash;</span>
                      ) : (
                        `${Data.email}`
                      )}
                    </p>
                  </div>
                </div>
                <div className="input_info_box input_info_box_profile  input_info_edit">
                  <div className="first_half">
                    <p className="lable_tag">Location</p>
                    {isEdit ? (
                      <input
                        type="text"
                        value={Data.location}
                        onChange={(e) =>
                          dispatch(
                            addCoachFormData({ location: e.target.value })
                          )
                        }
                      />
                    ) : (
                      <p className="lable_value ">{Data.location}</p>
                    )}
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Gender</p>
                    {isEdit ? (
                      <div className=" mydict_viewAccount_gender">
                        <div className="flex flex-wrap mt-2 justify-start">
                          <label>
                            <input
                              type="radio"
                              name="radio"
                              value={Data.gender}
                              onChange={() =>
                                dispatch(addCoachFormData({ gender: "Male" }))
                              }
                            />
                            <span
                              className={
                                Data.gender === "Male" ? "genderColors" : ""
                              }
                            >
                              Male
                            </span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="radio"
                              value={Data.gender}
                              onChange={() =>
                                dispatch(addCoachFormData({ gender: "Female" }))
                              }
                            />
                            <span
                              className={
                                Data.gender === "Female" ? "genderColors" : ""
                              }
                            >
                              Female
                            </span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="radio"
                              value={Data.gender}
                              onChange={() =>
                                dispatch(addCoachFormData({ gender: "Trans" }))
                              }
                            />
                            <span
                              className={
                                Data.gender === "Trans" ? "genderColors" : ""
                              }
                            >
                              Don't want to say
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <p className="lable_value ">
                        {Data.gender === undefined ? (
                          <span>&ndash;</span>
                        ) : (
                          `${Data.gender}`
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/* ABOUT US */}
              <div className={`setting_box ${isEdit ? "edit_mode" : ""}`}>
                <div className="profile_box_heading_div">
                  <h2>About</h2>
                  {isEdit ? (
                    <div className="flex">
                      <p
                        className="cancelButton"
                        onClick={() => {
                          setEdit(false);
                          setEditAbout(false);
                        }}
                      >
                        Cancel
                      </p>
                      <p className="saveButton" onClick={handleSubmit}>
                        Save
                      </p>
                    </div>
                  ) : (
                    <button
                      className="edit_button"
                      onClick={() => {
                        setEdit(true);
                        setEditAbout(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="input_info_box">
                  <div className="about_box  ">
                    {isEdit ? (
                      <ReactQuill
                        theme="snow"
                        value={Data.about}
                        onChange={handleAbout}
                      />
                    ) : (
                      <>
                        <div
                          className="  ql-editor list-disc list-inside"
                          dangerouslySetInnerHTML={{ __html: Data.about }}
                        ></div>
                        {/* {text === "" ? (
                          <></>
                        ) : (
                          <div>
                            <div className="text-[var(--blue)] text-[1rem] break-words">
                              {text.length > 100 ? (
                                <div>
                                  {" "}
                                  {showMore ? (
                                    text
                                  ) : (
                                    <p>{`${text.substring(0, 80)}...`}</p>
                                  )}
                                  <span
                                    className="text-[var(--link)] cursor-pointer text-sm"
                                    onClick={() => {
                                      setShowMore(!showMore);
                                    }}
                                  >
                                    <br />
                                    {showMore ? " show less" : "show more"}
                                  </span>
                                </div>
                              ) : (
                                <div>{text}</div>
                              )}
                            </div>
                          </div>
                        )} */}
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* <Aboutus
                setEditAbout={setEditAbout}
                editProfile={editProfile}
                editSetting={editSetting}
                about={about}
                setAbout={setAbout}
                handleSubmit={handleSubmit}
              /> */}
              <div className="setting_box">
                <div className="profile_box_heading_div">
                  <h2>You Train</h2>
                  <p onClick={() => setEditClicked(!editClicked)}>
                    {editClicked ? (
                      <>
                        <div className="flex">
                          <p
                            className="cancelButton"
                            onClick={() => {
                              setEdit(false);
                              setEditProfile(false);
                            }}
                          >
                            Cancel
                          </p>
                          <p className="saveButton" onClick={handleSubmit}>
                            Save
                          </p>
                        </div>{" "}
                      </>
                    ) : (
                      <div className="edit_button m-0 ml-[0.3rem]">Edit</div>
                    )}
                  </p>
                </div>
                <EditFormYouTrain
                  getData={getData}
                  run={run}
                  setRun={setRun}
                  swim={swim}
                  setSwim={setSwim}
                  bike={bike}
                  setBike={setBike}
                  triathlon={triathlon}
                  setTriathlon={setTriathlon}
                  editClicked={editClicked}
                />
              </div>

              {/* certificates */}

              <Certificate
                certificates={certificates}
                setCertificates={setCertificates}
                handleSubmit={handleSubmit}
              />

              {/* Video */}
              <EditVideo
                getData={getData}
                videoUrl={videoUrl}
                setVideoPath={setVideoPath}
                handleVideo={handleVideo}
              />

              <div className="view_form_back_btn">
                <Link to="/">&#8592; Back</Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EditCoachForm;

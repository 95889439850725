import axios from "axios";
import { Header } from "api/Signin";

// export async function getApis(urlPath) {
//   const header = Header();
//   return axios
//     .get(urlPath, {
//       headers: header,
//     })
//     .then((res) => {
//       // log specific url res only => path in string eg:- /files/get/2
//       if (urlPath.includes("change_path_to_log")) {
//         console.log(res);
//       }

//       if (res.data.status_code === 1) {
//         return res;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// }

export async function getApis(urlPath) {
  const header = Header();

  try {
    const res = await axios.get(urlPath, { headers: header });

    // log specific url res only => path in string eg:- /files/get/2
    if (urlPath.includes("/workoutsteps/get/")) {
    }

    if (res.data.status_code === 1) {
      return res;
    }

    if (res.data.status_code === 2) {
      return res;
    }

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Check if error is an AxiosError
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Server Error: ${error.response.status}`,
          error.response.data
        );
        return { error: error.response.data, status: error.response.status };
      } else if (error.request) {
        // Request was made but no response was received
        console.error(
          "Network Error: No response received from the server",
          error.request
        );
        return {
          error: "Network Error: No response received from the server",
          details: error.message,
        };
      } else {
        // Error occurred while setting up the request
        console.error("Request Setup Error:", error.message);
        return { error: error.message };
      }
    } else {
      // Handle non-Axios errors
      console.error("Unexpected Error:", error);
      return { error: "An unexpected error occurred" };
    }
  }
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status_code: "",
  name: "",
  ref: "",
  email: "",
  mobile: "",
  gender: "",
  location: "",
  dob: "",
  athlete_type: "",
  athlete_type_code: "",
  active_since: "",
  device_type: "",
  pool_length: "",
  profiles: "",
  upcoming_events: "",
};

const viewAccountData = createSlice({
  name: "ViewAccountData",
  initialState: initialState,
  reducers: {
    UserData: (state, action) => {
      // state = action.payload.viewAccountData;
      return { ...action.payload.viewAccountData };
    },
    addViewAccountData: (state, action) => {
      // Ensure only existing properties are updated
      Object.keys(action.payload).forEach((key) => {
        if (key in state) {
          state[key] = action.payload[key];
        }
      });
    },
  },
});

export const { UserData, addViewAccountData } = viewAccountData.actions;
export default viewAccountData.reducer;
